:root {
  --background: #f6f7f8;
  --card-bg: #ffffff;
  --text-color: #1c1c1c;
  --primary-accent: #ff4500;
  --secondary-accent: #0079d3;
  --border-color: #e4e6eb;
  --input-bg: #f0f2f5;
}

body {
  background-color: var(--background);
  font-family: Arial, sans-serif;
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card h2 {
  color: var(--primary-accent);
}

button {
  background-color: var(--primary-accent);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-accent);
}

input, select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--input-bg);
}

h1, h2 {
  text-align: center;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.responsive-table {
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: center;
  background-color: var(--card-bg);
}

.responsive-table th, .responsive-table td {
  border: 1px solid var(--border-color);
  padding: 8px;
}

.responsive-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: var(--text-color);
}

.responsive-table tr:nth-child(even) {
  background-color: var(--input-bg);
}

.responsive-table tr:hover {
  background-color: #e8eff5;
}

td {
  text-align: center;
  color: var(--text-color);
}


/* Slide-in editor */
.slide-in-editor {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 400px;
  background-color: var(--card-bg);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.slide-in-editor h3 {
  margin-bottom: 20px;
  color: var(--primary-accent);
}

.subject-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.subject-edit input {
  width: 60px;
  padding: 5px;
}

.editor-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.editor-actions button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--secondary-accent);
  color: #fff;
  border-radius: 4px;
  border: none;
}

.editor-actions button:hover {
  background-color: var(--primary-accent);
}

/* Responsive Design for Mobile */
@media screen and (max-width: 768px) {
  .responsive-table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    display: none;
  }

  tbody tr {
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
  }

  tbody td {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    text-align: right;
    border: none;
    border-bottom: 1px solid var(--border-color);
  }

  tbody td:before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    text-align: left;
  }

  button {
    font-size: 14px;
    padding: 8px;
  }
}

/* General Form Styles */
.add-student-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Input Styles */
.form-input {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--input-bg);
  font-size: 16px;
  color: var(--text-color);
}

/* Button Styles */
.add-button {
  background-color: var(--primary-accent);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: 16px;
  margin-bottom: 15px;
}

.add-button:hover {
  background-color: var(--secondary-accent);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .add-student-form {
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  }

  .form-input {
    min-width: 100%;
    max-width: 100%;
  }

  .add-button {
    width: 100%;
    font-size: 18px;
  }
  .side-bar{
    width: 100%;
  }
}
